import React from "react";

import Carousel from "./Carousel.js";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

// core components

function Desc() {
  return (
    <>
      <div className="p-4" id="programa"></div>
      <div className="section section-basic">
        <Container>
          <h3 className="title">Programa Jornadas Escribientes</h3>
          <h4>Formas de colaboración en la escritura académica</h4>
          <Row>
            <Col md="6">
            <p className="category">Programa</p>
              <p>
              El programa Jornadas Escribientes (JE) está dirigido a estudiantes e investigadores 
              interesados en los procesos de escritura colaborativa. El taller ofrece ejercicios 
              metodológicos, espacio y tiempo para el debate sobre la escritura argumentativa, 
              así como el trabajo individual académico. La propuesta parte de pensar en la escritura 
              como una forma de compartir las ideas con otros y de crear una comunidad solidaria. 
              Por lo tanto, el objetivo radica en proporcionar un espacio para que los residentes 
              puedan socializar sus procesos y generar un diálogo enriquecedor dentro de sus comunidades académicas. 
              </p>
            </Col>
            <Col md="6">
            <p className="category">Actividades</p>
              <p>
              Las actividades están diseñadas para detonar el desarrollo de resolución de problemas 
              de investigación en grupo a partir del pensamiento crítico. El taller parte desde cómo 
              formular un punto de vista, definir temas, realizar preguntas y afirmaciones; así como 
              cuestionar cuál es la relevancia, el aporte y el sustento de un proyecto de investigación 
              académica. La expectativa del programa persigue que los residentes rompan con la idea de que el 
              trabajo de escritura es solitario, puedan aprender unos de otros y continúen con el intercambio 
              durante su regreso a partir del uso herramientas digitales. 
              </p>
            </Col>
          </Row>

          <div className="p-4" id="casa"></div>
          <hr/>

          <h3 className="title">Casa Xamilpa</h3>
          <h4>Tlayacapan, Morelos</h4>
          <Row>
            <Col md="5">
            <p className="category">Lugar </p>
            <p>
            El espacio es una casa de campo familiar a las faldas del cerro Tlatoani.
            </p>
            <p className="category">Espacios de trabajo y descanso</p>
            <p>
            Cuenta con un taller estudio, Wifi, tableros, pizarrones, 
            así como salas de descanso y espacios de trabajo compartidos  al aire libre. 
            </p>
            <p className="category">Espacios para dormir y comer</p>
            <p>
            Habitaciones compartidas y uso libre otros espacios como cocina común, comedor y sala para reunirse.
            </p>
            <p className="category">Región</p>
            <p>
            Tlayacapan es un municipio en el noroeste de Morelos. Durante la estancia será posible visitar el 
            pueblo y los senderos aledaños a la casa, correr en campo abierto o realizar caminatas.
            </p>

            <p>
              <Button
                className="btn-primary"
                target="_blank"
                href="https://www.airbnb.com/rooms/32103458?check_in=2022-01-21&check_out=2022-01-23&guests=1&adults=1&s=67&unique_share_id=e4f69abe-9915-4c36-9663-a9f2e8a6d6a7"
              >
                Visita la Casa Xamilpa
              </Button>
            </p>
            </Col>
            <Col md="7">
              <Carousel></Carousel>
            </Col>
          </Row>

          <div className="p-4" id="itinerario"></div>
          <hr />

          <h3 className="title">Itinerario</h3>
          <Row>
            <Col md="4">
              <p className="category">Viernes 18</p>
              <p>
              15:00 - 18:30 · <strong>Recepción </strong><br/>
              18:30 - 19:30 · <strong>Charla de bienvenida </strong><br/>
              19:30 - 20:00 · <strong>Libre </strong><br/>
              20:00 - 21:00 · <strong>Cena</strong><br/>
              </p>
              <p className="category">Sábado 19</p>
              <p>
              9:00 - 10:00 · <strong>Desayuno </strong><br/>
              10:00 - 10: 30 · <strong>Definición de escritura colaborativa</strong><br/>
              10:30 - 11: 00 · <strong>La investigación y formas de escritura</strong><br/>
              11:00 - 11:15 · <strong>Descanso </strong><br/>
              11:15 - 12:00 · <strong>Conectar con tu lector</strong><br/>
              12:00 - 12:10 · <strong>Descanso </strong><br/>
              12:10 - 13:00 · <strong>El rol del investigador</strong><br/>
              13:00 - 13:10 · <strong>Descanso </strong><br/>
              </p>
            </Col>
            <Col md="4">
              <p>
              13:10 - 14:00 · <strong>Preguntas {'&'} Respuestas</strong><br/>
              14:00 -15:00 · <strong>Comida </strong><br/>
              15:00 -17:00 · <strong>Libre </strong><br/>
              17:00 - 17:45 · <strong>De temas a preguntas </strong><br/>
              17: 45 - 18:30 · <strong>De preguntas a problemas </strong><br/>
              18:30 - 18:45 · <strong>Descanso </strong><br/>
              18:45 - 19:30 · <strong>De problemas a fuentes </strong><br/>
              19:30 - 20:00 · <strong>Libre </strong><br/>
              20:00 - 21:00 · <strong>Cena</strong><br/>
              </p>
              <p className="category">Domingo 20</p>
              <p>
              9:00 - 10:00 · <strong>Desayuno </strong><br/>
              10:00 - 12:30 · <strong>Libre </strong><br/>
              12:30 - 13:15 · <strong>Búsqueda de evidencias </strong><br/>
              13:15 - 13:25 · <strong>Descanso </strong><br/>
              13:25 - 14:15 · <strong>Encontrar argumentos </strong><br/>
              </p>
            </Col>
            <Col md="4">
              <p>
              14:15 - 15:15 · <strong>Comida</strong><br/>
              15:15 - 16:30 · <strong>Libre </strong><br/>
              16:30 - 17:15 · <strong>Sustentar las ideas </strong><br/>
              17: 15 - 18:00 · <strong>Anticipar objeciones</strong><br/>
              18:00 - 18:30 · <strong>Descanso</strong><br/>
              18:30 - 19:30 · <strong>Relevancia y motivos</strong><br/>
              19:45 - 20:00 · <strong>Descanso</strong><br/>
              20:00 - 21:00 · <strong>Cena</strong><br/>
              </p>
              <p className="category">Lunes 21</p>
              <p>
              9:00 - 10:30 · <strong>Desayuno </strong><br/>
              10:30 - 11:30 · <strong>Experiencias para formar un grupo de escritura académica </strong><br/>
              11:30 - 12:00 · <strong>Reflexión individual </strong><br/>
              12:00 - 13:30 · <strong>Comentarios de la residencia </strong><br/>
              13:30 - 14:30 · <strong>Comida y despedida</strong><br/>
              </p>
            </Col>
          </Row>

          <div className="p-4"></div>
          <hr/>

          <h3 className="title">La residencia está orientada a quienes quieren:</h3>
          <Row>
            <Col md="6">
            <p>{'>'} ESCRIBIR UN PROTOCOLO DE INVESTIGACIÓN, TESIS, ENSAYOS O UN LIBRO</p>
            <p>{'>'} UN MOMENTO DE PAUSA EN EL PROCESO DE ESCRITURA PARA DELIMITAR EL TEMA, REFLEXIONAR SOBRE LAS AFIRMACIONES, LOS ARGUMENTOS Y/O LAS PREGUNTAS DE INVESTIGACIÓN</p>
            <p>{'>'} COMPARTIR LOS PROCESOS DE ESCRITURA ACADÉMICA Y SU INVESTIGACIÓN </p>
            <p>{'>'} PROFUNDIZAR SUS CONOCIMIENTOS SOBRE LA ARGUMENTACIÓN</p>
            <p>{'>'} INICIAR UNA COMUNIDAD DE ESCRITURA ACADÉMICA</p>
            <p>{'>'} REALIZAR UNA INVESTIGACIÓN INDEPENDIENTE</p>
            </Col>
            <Col md="2">
            </Col>
            <Col md="4">
              <p className="blockquote">
              Dirigida a estudiantes y  profesionales de las humanidades, artes, ciencias sociales y ciencias.
              <span className="badge badge-info">
              </span>
              </p>
            </Col>
          </Row>
          <div className="p-4" id="inscripciones"></div>
          <hr/>

          <h3 className="title">Inscripciones</h3>
          <h4>INVERSIÓN: <span className="badge badge-primary">$ 7,200 MXN</span></h4>
          <Row>
            <Col md="8">
            <p>
            Aplicaciones antes del 26 de enero $6,000
  A partir del 27 de enero $7,200
            
  Inscripciones abiertas hasta el 28 de febrero del 2022
            </p>
            </Col>
            <Col className="text-right">
            <h4>

            <Button
                className="btn-primary"
                target="_blank"
                href="https://docs.google.com/forms/d/1R9nCqAeN8C2pma78ZjKrwDw_61L0DTHZS53x2QzkPc4"
              >
                Aplica aquí
              </Button>
            </h4>
            </Col>
          </Row>

          <Row>
            <Col>
            <p className="category">INCLUYE</p>
            <ul>
              <li>Workshop: taller, espacio para el trabajo y el intercambio</li>
              <li>Materiales digitales de lectura y pequeño manual de redacción </li>
              <li>Comidas (desayuno, almuerzo, cena y coffee break)</li>
              <li>Wifi y los servicios para la estancia</li>
              <li>Alojamiento en espacios compartidos durante 3 noches</li>
            </ul>
            </Col>
            <Col>

            <p className="category">NO INCLUYE</p>
            <ul>
              <li>Traslado al sitio a la estancia</li>
              <li>Guía para recorrer las capillas y el pueblo ni por los senderos, si es requerido pregunten para programarlo</li>
            </ul>
            </Col>
          </Row>


          <hr/>

          <h3 className="title">Coordinadora del Taller</h3>
          
          <Row>
            <Col md="4" className="d-flex justify-content-center align-items-center">
                <img
                  alt="..."
                  className="rounded-circle img-fluid img-raised w-50 text-center"
                  src={require("assets/img/cris_sq.jpg").default}
                ></img>
            </Col>
            <Col md="8">
            <h4>Cristina del Río Francos</h4>
            <p>
            Maestra en Arte y Estética<br/>
            Estudios de licenciatura en Literatura Hispánica. 
            </p>
            <p>
            Docente de talleres de escritura creativa, lectura y redacción, 
            metodologías de investigación, comunicación comunitaria, a nivel de licenciatura y preparatoria. 
            </p>
            <p>
            Ha participado en la comunicación de proyectos de arte contemporáneo en la Ciudad de México 
            y estrategias de comunicación en la fundación de la UAM Lerma. 
            </p>



            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
}

export default Desc;
