import React from "react";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="#"
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("header")
                  .scrollIntoView({ behavior: 'smooth', block: 'start'})
              }}
              id="navbar-brand"
            >
              Jornadas Escribientes
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="#programa"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("programa")
                      .scrollIntoView({ behavior: 'smooth', block: 'start'})
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                  }}
                >
                  <p>Programa</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="#casa"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("casa")
                      .scrollIntoView({ behavior: 'smooth', block: 'start'})
                      document.documentElement.classList.toggle("nav-open");
                      setCollapseOpen(false);
                  }}
                >
                  <p>Casa Xamilpa</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="#itinerario"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("itinerario")
                      .scrollIntoView({ behavior: 'smooth', block: 'start'})
                      document.documentElement.classList.toggle("nav-open");
                      setCollapseOpen(false);
                  }}
                >
                  <p>Itinerario</p>
                </NavLink>
              </NavItem>


              <NavItem>
                <Button
                  className="nav-link btn-neutral"
                  color="default"
                  href="#inscripciones"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("inscripciones")
                      .scrollIntoView({ behavior: 'smooth', block: 'start'})
                      document.documentElement.classList.toggle("nav-open");
                      setCollapseOpen(false);
                  }}
                >
                  <p>Inscripciones</p>
                </Button>
              </NavItem>



              <NavItem>
                <NavLink
                  href="https://www.facebook.com/casaxamilpa/"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/casaxamilpa/"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
