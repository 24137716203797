/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components

function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div id="header" className="page-header clear-filter" filter-color="black">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/header2.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand">
            <img
              alt="..."
              className="n-logo"
              src={require("assets/img/j-logo.png").default}
            ></img>
            <h1 className="h1-seo">Jornadas <br />Escribientes</h1>
            <h3>
              Taller y residencia de escritura<br />
              en Casa Xamilpa, Tlayacapan, Morelos<br />
              18-21 de marzo, 2022<br />
              Formas de colaboración en la escritura académica<br />
            </h3>
          </div>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
